import React from 'react'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'
import { prepareUrl } from '../../utils'

import { Container, HeaderContainer } from './style'
import { ButtonFromBackend } from '../common/button'
import { authorize, isLoggedIn } from '../../services/authentication'

const ConditionalButton = ({ item, index }) => {
  if (
    isLoggedIn() &&
    (item.link.url === '/logg-inn/' || item.link.url === '/logg-inn')
  ) {
    return (
      <button
        key={index}
        className={
          'text-center font-medium w-max px-[18px] pt-[14px] pb-[16px] rounded-full text-[17px] leading-[17px] border-2 border-transparent focus:ring focus:ring-orange-light bg-orange-main text-white hover:bg-orange-light disabled:bg-gray-200 disabled:text-gray-700'
        }
        onClick={() => authorize()}
      >
        Gå til Proteket Online
      </button>
    )
  } else if (
    isLoggedIn() &&
    (item.link.url === '/registrer-bruker/' ||
      item.link.url === '/registrer-bruker')
  ) {
  } else
    return (
      <ButtonFromBackend
        key={index}
        path={prepareUrl(item.link.url)}
        target={item.link.target}
        variant={item.variant}
      >
        {item.link.title}
      </ButtonFromBackend>
    )
}

const Aside = ({
  buttons,
  title,
  content,
  media,
  index,
  isOutlined,
  template,
  id,
}) => {
  const isOdd = index % 2 !== 0
  return (
    <div
      id={id ?? ''}
      className={`w-screen overflow-hidden relative z-20
        ${
          template === 'Proteketonline Template' ? 'bg-transparent' : 'bg-white'
        }
    `}
    >
      <div
        className={`xl-container mx-auto overflow-hidden relative z-20
    ${template === 'Proteketonline Template' ? 'bg-transparent' : 'bg-white'}
    `}
      >
        <Container isOutlined={isOutlined}>
          <HeaderContainer isOdd={isOdd}>
            <header>
              <h2 className={'text-3xl sm:text-4xl font-semibold'}> {title}</h2>
            </header>
            <main>
              <div
                className={`text-lg sm:text-xl
              ${!buttons && 'pb-4'}
              `}
              >
                {parse(content)}
              </div>
              {buttons && (
                <div
                  className={
                    'flex flex-wrap gap-y-2 gap-x-2 mt-6 mb-4 items-center'
                  }
                >
                  {buttons.map((item, index) => (
                    <ConditionalButton key={index} item={item} />
                  ))}
                </div>
              )}
            </main>
          </HeaderContainer>
          <aside className={'w-full lg:w-1/2'}>
            {media.mediaType === 'image' && (
              <img
                className={
                  'mx-auto w-full max-h-[258px] sm:max-h-[540px] lg:max-h-full lg:w-full rounded-xl object-cover'
                }
                src={media.image.publicUrl}
                alt={media.image.altText ? media.image.altText : ''}
              />
            )}
            {media.mediaType === 'video' && (
              <video
                className={'mx-auto rounded-xl'}
                controls
                height={'100%'}
                width={'100%'}
              >
                <source src={media.video.publicUrl} type={'video/mp4'} />
                <source src={media.video.publicUrl} type={'video/webm'} />
              </video>
            )}
          </aside>
        </Container>
      </div>
    </div>
  )
}

export default Aside

Aside.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  media: PropTypes.any,
  index: PropTypes.number,
  isOutlined: PropTypes.bool,
}