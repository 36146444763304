import tw, { styled } from 'twin.macro'

export const HeaderContainer = styled.div(({ isOdd }) => [
  tw`lg:w-1/2 flex flex-col gap-y-4 pb-10 sm:pb-0`,
  isOdd && tw`lg:order-last`,
])

export const Container = styled.article(({ isOutlined }) => [
  tw`
  
  flex 
  flex-col 
  gap-x-32 
  justify-between 
  lg:items-center
  items-start
  py-4
  md:py-10
  lg:py-16

  
  lg:flex-row 
 `,

  isOutlined &&
    tw`
  mb-6

    p-4
  
  
  sm:p-6
  
  md:pt-[40px] 
  md:px-6 
  md:pb-6 
  
  lg:p-16
  lg:mb-12
  
  border 
  rounded-xl 
  border-blue-lighter`,
])
