import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import ProteketOnline from '../components/proteket-online'
import SEO from '../components/seo'

const ProteketOnlineTemplate = ({ data, location }) => {
  const { wpPage: page } = data
  const template = page.template.templateProteketOnline

  const asides = page.template.templateProteketOnline.asides ?? []
  return (
    <Layout template={page.template.templateName} location={location}>
      <SEO
        title={page.title}
        metaTitle={page.seo.title}
        description={page.seo.metaDesc}
      />
      <ProteketOnline
        title={template.headingText}
        featuredImage={page.featuredImage.node}
        asides={asides}
        template={page.template.templateName}
        cta={{
          title: template.title,
          button: template.button,
          quote: template.quote,
          image: template.image,
          name: template.name,
          jobTitle: template.jobTitle,
        }}
      />
    </Layout>
  )
}

export default ProteketOnlineTemplate

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      seo {
        breadcrumbs {
          text
          url
        }
        metaDesc
        title
      }
      template {
        ... on WpProteketonlineTemplate {
          templateName
          templateProteketOnline {
            asides {
              id
              buttonList {
                link {
                  target
                  title
                  url
                }
                variant
              }
              content
              title
              media {
                mediaType
                image {
                  altText
                  title
                  publicUrl
                }
                video {
                  publicUrl
                  title
                  altText
                }
              }
            }
            button {
              target
              title
              url
            }
            headingText
            image {
              altText
              publicUrl
            }
            jobTitle
            name
            quote
            title
          }
        }
      }
      featuredImage {
        node {
          altText
          publicUrl
        }
      }
    }
  }
`
