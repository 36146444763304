import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../common/button'

const ProteketOnlineCallToAction = ({
  title,
  button,
  quote,
  image,
  name,
  jobTitle,
}) => {
  return (
    <>
      {title && button && quote && image && name && jobTitle && (
        <div className={'w-screen bg-blue-dark py-14 md:py-20'}>
          <div
            className={
              'flex flex-col gap-y-14 items-center xl-container mx-auto'
            }
          >
            <h3
              className={
                'text-white text-center font-medium text-3xl md:text-4xl lg:text-5xl max-w-[897px]'
              }
            >
              {title}
            </h3>
            <div className={'max-w'}>
              <Button
                variant={'contained'}
                color={'light_orange'}
                path={button.url}
                target={button.target}
              >
                {button.title}
              </Button>
            </div>
            <div className={'flex flex-col max-w-[677px] gap-y-5'}>
              <div
                className={
                  'bg-gray-100 rounded-[32px] px-6 pt-[14px] pb-[17px] relative text-center after:bottom-[-18px] after:absolute after:border-t-[10px] after:border-r-8 after:border-b-8 after:border-l-8 after:border-t-gray-100 after:border-b-transparent after:border-l-transparent after:border-r-transparent after:-translate-x-1/2 after:left-1/2'
                }
              >
                {quote}
              </div>
              <div className={'flex flex-col items-center gap-y-1'}>
                <img
                  className={'rounded-full h-24 w-24'}
                  src={image.publicUrl}
                  alt={image.altText ? image.altText : ''}
                />
                <p className={'text-white'}>{name}</p>
                <p className={'text-white text-sm'}>{jobTitle}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ProteketOnlineCallToAction

ProteketOnlineCallToAction.propTypes = {
  title: PropTypes.string,
  button: PropTypes.object,
  quote: PropTypes.string,
  image: PropTypes.object,
  name: PropTypes.string,
  jobTitle: PropTypes.string,
}
