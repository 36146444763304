import React from 'react'
import PropTypes from 'prop-types'
import Aside from '../aside'
import ProteketOnlineCallToAction from './call-to-action'

const ProteketOnline = ({ title, featuredImage, asides, cta, template }) => {
  return (
    <div className={'w-screen bg-orange-lighter h-full'}>
      <div
        className={
          'xl-container mx-auto pt-32 md:pt-52 lg:pt-64 flex flex-col gap-y-16 md:gap-y-48 lg:gap-y-52'
        }
      >
        <h1
          className={
            'text-orange-dark text-center text-4xl md:text-5xl lg:text-6xl font-medium text-center max-w-screen-lg lg:max-w-screen-lg mx-auto'
          }
        >
          {title}
        </h1>
        <div>
          <img
            className={'w-full mb-12 md:mb-20 lg:mb-32 rounded-xl'}
            src={featuredImage.publicUrl}
            alt={featuredImage.altText ? featuredImage.altText : ''}
          />
        </div>
      </div>

      {asides.length > 0 && (
        <div id={'asides'}>
          {asides.map((item, index) => (
            <Aside
              key={index}
              index={index}
              buttons={item.buttonList}
              title={item.title}
              content={item.content}
              media={item.media}
              isOutlined={false}
              template={template}
              id={item.id}
            />
          ))}
        </div>
      )}
      <ProteketOnlineCallToAction
        title={cta.title}
        button={cta.button}
        image={cta.image}
        jobTitle={cta.jobTitle}
        name={cta.name}
        quote={cta.quote}
      />
    </div>
  )
}

export default ProteketOnline

ProteketOnline.propTypes = {
  title: PropTypes.string,
}
